import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { AuthenticateService } from 'src/app/modules/authenticate/services/authenticate.service';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { ApiUrls, userAccountUrls } from '../../enum/routes';
import { CLEAR_CACHE_HEADER } from '../http-cache/http-cache.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements HttpInterceptor {
  refreshTokenInProgress = false;
  platformId: any;
  constructor(
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
    private authenticateService: AuthenticateService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  private handleDisplayError(
    req: HttpRequest<any>,
    error: HttpErrorResponse
  ): void {
    const isServerPlatform = isPlatformServer(this.platformId);
    const isExcludedUrl =
      req.url.includes(userAccountUrls.refreshToken) ||
      req.url.includes(ApiUrls.FAQ) ||
      req.url.includes(ApiUrls.logProductSearch);

    if (isServerPlatform || isExcludedUrl) {
      return;
    }

    const errorMsg =
      error.error instanceof HttpErrorResponse
        ? `Error: ${error.error.message}`
        : `Error: ${error.error?.detail || 'An unknown error occurred'}`;
    this.snackBarService.showSnackBar(errorMsg, 'primary', 2000);
  }

  private handleErrorRedirects(error: HttpErrorResponse): void {
    const redirectConfigs: {
      [key: string]: { path: string; queryParams: { [key: string]: string } };
    } = {
      '4132': { path: '/profile', queryParams: { tab: 'billing-info' } },
      '4155': { path: '/profile', queryParams: { tab: 'edit' } },
    };
    const config = redirectConfigs[error.error?.code];
    if (config) {
      this.router.navigate([config.path], {
        queryParams: config.queryParams,
      });
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error?.code === 4041 || error.error?.code === 4177) {
          if (!this.localStorageService.isLoggedIn) {
            this.localStorageService.logOutUser();
            return;
          }
          if (
            !this.refreshTokenInProgress &&
            this.localStorageService.getItem('refreshToken')
          ) {
            this.refreshTokenInProgress = true;
            let refresh = this.localStorageService.getItem('refreshToken');
            return this.authenticateService.refreshToken(refresh).pipe(
              switchMap((res) => {
                this.localStorageService.setItem(
                  'refreshToken',
                  res.refresh_token
                );
                this.localStorageService.setItem('accessToken', res.token);
                this.refreshTokenInProgress = false;
                req = req.clone({
                  setHeaders: {
                    Token: `${res.token}`,
                    [CLEAR_CACHE_HEADER]: 'true',
                  },
                });
                return next.handle(req);
              }),
              catchError((e) => {
                this.localStorageService.logOutUser();
                window.location.reload();
                return next.handle(req);
              })
            );
          } else {
            this.localStorageService.logOutUser();
          }
        }

        if (
          !this.localStorageService.isLoggedIn &&
          error.status === 401 &&
          error.error?.code !== 4041
        ) {
          this.localStorageService.logOutUser();
        }

        this.handleDisplayError(req, error);
        this.handleErrorRedirects(error);
        //   if(error.error?.code == '4162' || error.error?.code == '4161'){
        //     this.router.navigate(['/profile'], {
        //       queryParams: {
        //         tab: 'kyc-form',
        //       },
        //     });
        // }
        return throwError(error);
      })
    );
  }
}
