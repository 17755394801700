import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  NavigationStart,
  Router,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { StateService } from './modules/shared/services/storage/state.service';
import { StoreDetailService } from './modules/shared/services/resolver/store-detail.service';
import { DynamicMetaService } from './modules/shared/services/dynamic-meta.service';
import { TitleService } from './modules/shared/services/title.service';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { CookieSettingsComponent } from './modules/shared/components/cookie-settings/cookie-settings.component';
import { CookieService } from './modules/shared/services/cookie.service';
import { PaginationService } from './modules/shared/services/pagination/pagination.service';
import { BinanceService } from './modules/shared/services/binance/binance.service';
import { DynamicScript } from './modules/shared/services/dynamic-script.service';
import { ClarityService } from './modules/shared/services/clarity/clarity.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public showLoading: boolean;
  public isBlock: boolean = false;
  public isOffline: boolean = false;
  storeDetail: any;
  isLoggedIn: boolean = false;
  isFirstCall: boolean = true;
  platformId: Object;
  cookieDialogRef: MatDialogRef<any>;
  constructor(
    private stateService: StateService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private storeDetailService: StoreDetailService,
    private titleService: TitleService,
    private dynamicMetaService: DynamicMetaService,
    private cookieService: CookieService,
    private paginationService: PaginationService,
    private loadScript: DynamicScript,
    private binanceService: BinanceService,
    private dialog: MatDialog,
    private clarityService: ClarityService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.platformId = platFormId;
  }

  ngOnInit() {
    this.setLoginStatus();
    this.checkLoading();
    this.checkRoute();
    this.setScripts();
    this.loadScript.loadFreshwork();
    this.clarityService.init();
  }

  checkCookieDialog() {
    if (
      this.cookieDialogRef &&
      this.cookieDialogRef.getState() === MatDialogState.OPEN
    )
      return;

    const showDialog =
      !this.binanceService.isBinance && !this.cookieService.isConfirmed;
    if (showDialog) {
      this.cookieDialogRef = this.dialog.open(CookieSettingsComponent, {
        disableClose: true,
        hasBackdrop: false,
        maxWidth: '100vh',
        position: { bottom: '62px', left: '16px', right: '16px' },
        panelClass: 'cookie-dialog',
      });
    }
  }

  setLoginStatus() {
    this.stateService.getLoginStatus().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (this.isFirstCall && !this.isLoggedIn) {
        this.binanceService.autoLoginBinance();
      }
      this.isFirstCall = false;
    });
  }

  checkLoading() {
    this.stateService.getLoadingStatus().subscribe((loadingStatus) => {
      this.showLoading = loadingStatus;
      this.cdr.detectChanges();
    });
  }

  checkRoute() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.storeDetail = this.storeDetailService.permissionInit;
        this.titleService.updateTitle(this.storeDetail?.name);
        this.stateService.startLoading();
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.paginationService.clearNextPageMethod();
        this.dialog.closeAll();
        this.checkCookieDialog();
        this.stateService.endLoading();
        if (this.router.url.includes('search')) {
          this.stateService.setSearchStatus(true);
        } else {
          this.stateService.setSearchStatus(false);
        }
      }
    });
  }

  setScripts() {
    let storeDetail = this.storeDetailService.permissionInit;
    if (storeDetail?.scripts) {
      this.dynamicMetaService.loadMeta(storeDetail.scripts);
    }
  }
}
