<footer *ngIf="!hideNavigation">
  <div class="footer-shape-container">
    <img src="assets/images/icons/footer.svg" alt="" class="footer-shape" />
    <div class="circle-button pointer" (click)="scrollToTop()">
      <img src="assets/images/icons/arrow-up-white.svg" alt="" />
    </div>
  </div>
  <div class="main-footer w-full">
    <div class="container footer-wrapper w-full">
      <div class="footer-info">
        <img src="assets/images/logo-white.svg" alt="" class="logo" />
        <div class="flex footer-details">
          <div class="left-content">
            <p class="description">
              Explore our extensive collection of digital gift cards and e-pins
              from renowned brands and popular games. Easily purchase your
              preferred brands' digital gift cards .
            </p>
          </div>
          <div class="links-container">
            <h4 class="content-title">Links:</h4>
            <div class="links">
              <a href="/">Home</a>
              <!-- <a href="">Blog</a> -->
              <a href="/category/all">Categories</a>
              <a href="/pages/faq">FAQ</a>
              <a href="/pages/contact-us">Contact Us</a>
            </div>
          </div>
          <div class="social-info">
            <h4 class="content-title">Follow Us On Social Media:</h4>
            <div class="social-icons">
              <a href="mailto:support@kingiftcard.com">
                <img src="assets/images/icons/mailTo.svg" alt="Email" />
              </a>
              <a href="https://facebook.com/kingiftcard" target="_blank"
                ><img src="assets/images/icons/facebook.svg" alt="Facebook" />
              </a>
              <a href="https://instagram.com/king_giftcard/" target="_blank"
                ><img src="assets/images/icons/instagram.svg" alt="Instagram"
              /></a>
              <a href="https://t.me/kingiftcard" target="_blank">
                <img src="assets/images/icons/telegram.svg" alt="Telegram" />
              </a>
              <a href="https://x.com/kingiftcard" target="_blank">
                <img src="assets/images/icons/x.svg" alt="X" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-description flex">
        <p class="rights-detail"></p>
        <div class="info">
          <a href="/pages/cookie-policy">Cookie Policy</a>
          <a href="/pages/privacy-policy" class="dot-link">Privacy Policy</a>
          <a href="/pages/return-policy" class="dot-link">Return Policy</a>
        </div>
      </div>
    </div>
  </div>
</footer>
