import { Injectable } from '@angular/core';
import { FACEBOOK_EVENT_TYPES, FacebookEventOptions } from './meta-pixel.model';

declare const fbq;

@Injectable({
  providedIn: 'root',
})
export class MetaPixelService {
  constructor() {}

  pushEvent(eventName: FACEBOOK_EVENT_TYPES, options: FacebookEventOptions) {
    const { data } = options;
    fbq('track', eventName, data);
  }
}
