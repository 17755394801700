export enum FACEBOOK_EVENT_TYPES {
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  ADD_TO_WISHLIST = 'AddToWishlist',
  ADD_TO_CART = 'AddToCart',
  VIEW_CONTENT = 'ViewContent',
  PURCHASE = 'Purchase',
  SEARCH = 'Search',
}

export interface FacebookEventOptions {
  data: FacebookEventData;
}

export interface FacebookEventData {
  content_category?: string;
  content_ids?: (string | number)[];
  content_name?: string;
  content_type?: 'product';
  contents?: Content[];
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

interface Content {
  id: string;
  quantity: number;
}
