import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductSearch } from 'src/app/modules/shared/models/products.model';
import { RecentlySearchedService } from '../../services/recently-searched.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { PanelService } from '../../services/api/panel.service';
import { FACEBOOK_EVENT_TYPES } from '../../services/meta-pixel/meta-pixel.model';
import { MetaPixelService } from '../../services/meta-pixel/meta-pixel.service';

@Component({
  selector: 'search-brief-result',
  templateUrl: './search-brief-result.component.html',
  styleUrls: ['./search-brief-result.component.scss'],
})
export class SearchBriefResultComponent implements OnInit {
  readonly resultLimit = 3;
  @Input() searchResult: any[];
  @Input() query: string;

  constructor(
    private dialog: MatDialog,
    private recentlySearchedService: RecentlySearchedService,
    private googleTagManagerService: GoogleTagManagerService,
    private metaPixelService: MetaPixelService,
    private panelService: PanelService
  ) {}

  ngOnInit(): void {}

  closeModals() {
    this.dialog.closeAll();
  }

  onNavigate(item: ProductSearch) {
    this.logSearch(item.id);
    this.saveRecentlySearched(this.query);
    this.closeModals();
    this.sendProductEvent(item);
    this.sendSearchEvent(item, this.query);
  }

  private sendSearchEvent(item: ProductSearch, term: string) {
    this.googleTagManagerService.sendAnalytics('search', { term });
    this.metaPixelService.pushEvent(FACEBOOK_EVENT_TYPES.SEARCH, {
      data: {
        content_ids: [`${item.id}`],
        search_string: term,
        contents: [
          {
            id: `${item.id}`,
            quantity: 1,
          },
        ],
        content_type: 'product',
        currency: 'no-data',
        value: 1,
      },
    });
  }

  private logSearch(productId: number) {
    this.panelService
      .logProductSearch({
        product: productId,
        term: this.query,
      })
      .subscribe();
  }

  private sendProductEvent(product: ProductSearch) {
    const emptyTxt = '---';
    let analyticData = {
      currency: emptyTxt,
      value: product.id,
      id: product.id,
      name: product.name,
      discount_percent: emptyTxt,
      cashback_percent: emptyTxt,
      source: 'search',
      min_price: emptyTxt,
      max_price: emptyTxt,
    };

    this.googleTagManagerService.sendAnalytics('view_product', {
      ...analyticData,
    });
  }

  private saveRecentlySearched(keyword: string) {
    if (!keyword) return;
    this.recentlySearchedService.addKeyword(keyword);
  }
}
