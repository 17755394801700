import { Injectable, isDevMode } from '@angular/core';
import Clarity from '@microsoft/clarity';
const _ClarityProjectId = 'pjj0oott9l';

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  constructor() {}

  init() {
    if (isDevMode()) return;
    Clarity.init(_ClarityProjectId);
  }
}
